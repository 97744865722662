import React from 'react';
import './App.css';

import Amplify, { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
//import { AwsCognitoOAuthOpts } from '@aws-amplify/auth/src/types'

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';

var amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN + ".auth.ap-northeast-1.amazoncognito.com",
      scopes: ['openid'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN_URL,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT_URL,
      responseType: process.env.REACT_APP_RESPONSETYPE
    }
  },
}

Amplify.configure(amplifyConfig);

const providerName = process.env.REACT_APP_PROVIDER_NAME

Auth.configure()

const signIn = (provider:string|undefined) => () => {
  if(provider === undefined) {
    console.log("error: provider name is undefined!")
  } else {
    Auth.federatedSignIn({customProvider: provider}).then(credentials => {
      console.log(credentials)
    }).catch((e: any) => {
      console.log(e);
    });
  }
}

function App() {
  const [authState, setAuthState] = React.useState<string>();
  const [user, setUser] = React.useState<Object>();

  console.log("zzz")
  console.log(useParams())
  const { urlParam } = useParams()
  console.log(urlParam)

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      console.log(user)
      setUser(user);
      setAuthState('signedin')
    }).catch(e => {
      console.log(e)
      setAuthState('signin')
    })
    Auth.currentSession().then(session => {
      let t = session.getIdToken();
      console.log("token:");
      console.log(t);
      console.log('cognito:roles: ');
      console.log(t['payload']['cognito:roles']);
    });

    return onAuthUIStateChange((nextAuthState, authData) => {
      console.log(authState, user, " | ", nextAuthState, authData)
      setAuthState(nextAuthState);
      setUser(authData);
      console.log("after setting", authState, user, nextAuthState, authData)
    });
  // eslint-disable-next-line
  }, [])

  //let oauth: AwsCognitoOAuthOpts = (config.oauth as AwsCognitoOAuthOpts)
  //let oauth: any = (config.oauth as any)
  if(authState === AuthState.SignedIn && user) {
    return (
      <div className="App">
        <Paper>
          <Grid>
            <Grid>
              {urlParam}
            </Grid>
            <Grid>
              <Button 
               variant='outlined' 
               onClick={()=>{
                document.cookie='logoutRedirectURL=https://outsideauth.meltec.co.jp/GetAccess/Logout; max-age=60'
                //amplifyConfig.Auth.oauth.redirectSignOut = "https://outsideauth.meltec.co.jp/GetAccess/Logout";
                Auth.signOut()
               }}
              >
              ログアウト
              </Button>
            </Grid>
            <Grid>
              <Button 
               variant='outlined' 
               onClick={()=>{
                document.cookie='logoutRedirectURL=https://www.i-service.meltec.co.jp/; max-age=60'
                //oauth.redirectSignOut = "https://www.i-service.meltec.co.jp/";
                Auth.signOut()
               }}
               >
               サービス一覧に戻る
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
  else {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Button 
            variant="contained" 
            color="primary"
            onClick={signIn(providerName)}
          >
            ログイン
          </Button>
        </Grid>
      </Grid>
    )    
  }
}

export default App;
